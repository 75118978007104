import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const options = {
  indexAxis: 'y',
  elements: {
    bar: {
      borderWidth: 1,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: 'right',
    },
    title: {
      display: true,
      text: '',
    },
  },
};

const labels = ['Березень'];

export const HorizontalBar = ({ books }) => {
  return (
    <Bar
      options={options}
      data={{
        labels,
        datasets: [
          {
            label: '2024',
            data: [books.books2022[0]['книги']],
            borderColor: 'rgb(168,176,87)',
            backgroundColor: 'rgb(168,204,181)',
          },
          {
            label: '2025',
            data: [books.books],
            borderColor: 'rgb(211,192,181)',
            backgroundColor: 'rgb(211,223,235)',
          },
        ],
      }}
    />
  );
};
