import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import ChartPages from '../../components/ChartPages/ChartPages';
import { UserCard } from '../../components/UserCard/UserCard';
import { Medals } from '../../components/Medals/Medals';
import { Pie } from '../../components/Pie/Pie';
import { LoadingUserPage } from '../../components/Loading/LoadingUserPage';
import { HorizontalBar } from '../../components/HorizontalBar/HorizontalBar';

import back from '../../img/User/back.jpg';
import './UserMonthStatistics.scss';
import { db } from '../../utils/fire';
import { collection, getDocs } from 'firebase/firestore';

export const UserMonthStatistics = () => {
  const [data, setData] = useState([]);
  const userCollectionRef = collection(db, 'vipMonthStat');

  useEffect(() => {
    const getUsers = async () => {
      const data = await getDocs(userCollectionRef);
      try {
        let result = data.docs.map((doc) => ({
          ...doc.data(),
        }));
        setData(Object.values(result[0]));
      } catch (err) {
        console.log(err);
      }
    };
    getUsers();
  }, []);

  const { id } = useParams();
  const navigate = useNavigate();

  let user = data.filter((item) => item.siteId === id);

  const getGenres = (data) => {
    let sortData = data.filter((item) => item.name !== 'Без жанру' && item['жанр'] > 0);
    if (sortData.length) {
      let res = sortData.map((item) => [item.name, item['жанр']]);
      return res;
    } else {
      return 0;
    }
  };
  const getNoGenres = (data) => {
    let sortData = data.filter((item) => item.name === 'Без жанру');
    if (sortData[0]['жанр'] > 0) {
      return sortData[0]['жанр'];
    } else {
      return 0;
    }
  };
  const getBack = () => {
    navigate('/');
  };

  return data.length ? (
    <div className="UserMonthStatistics">
      <p className="title_User_Page">Твоя читацька статистика за березень 2025</p>
      <div className="cards">
        <div>
          <UserCard color="#c7e6d0" count={user[0].books} desc="Прочитано книг" className="card" />
          <UserCard color="#f7f7f7" count={user[0].reviews} desc="Відгуки" className="card" />
        </div>
        <div>
          <UserCard
            color="#a8ccb5"
            count={user[0].readDayNow}
            desc="Читаєш підряд"
            className="card"
          />
          <UserCard
            color="#d3dfeb"
            count={user[0].readDayMax}
            desc="Max дні читання"
            className="card1"
          />
        </div>
      </div>
      <div className="charts">
        <ChartPages
          title="Кількість прочитанних сторінок"
          data={user[0].pages}
          xAxis="index"
          yAxis="pages"
        />
        <div className="statistics">
          <div className="statistics_chart">
            <p className="title">Статистика книг</p>
            <HorizontalBar books={user[0]} />
          </div>
          <div className="statistics_pie">
            <p className="title">Жанри твоїх книг</p>
            <Pie data={getGenres(user[0].statGenres)} noGenres={getNoGenres(user[0].statGenres)} />
          </div>
        </div>
      </div>
      <div className="medals">
        <Medals user={user} />
      </div>
      <div className="user_footer">
        <div className="quote">
          <figure>
            <blockquote cite="#місяцьчитання">
              <p>Читай ще більше разом з марафоном #місяцьчитання</p>
            </blockquote>
          </figure>
        </div>
        <div className="blockBack">
          <img src={back} alt="" className="img" onClick={getBack} />
        </div>
      </div>
    </div>
  ) : (
    <div className="user">
      <p className="title_User_Page">Твоя читацька статистика за березень 2025</p>
      <LoadingUserPage />
    </div>
  );
};
